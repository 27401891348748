@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap);
html {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
@media only screen and (max-width: 800px) {
  .mainHeaderBG {
    height: 110px !important;
  }
  .h3Header {
    margin-bottom: 8px !important;
  }
  .full,
  .MainQuestion {
    padding: 0px !important;
    /* background-color: red; */
  }
  .tripLocation {
    margin-bottom: 12px !important;
  }
  .textBoxArea {
    width: 100% !important;
  }
  .moveToNext {
    padding: 0px !important;
  }
  .MainQueSet {
    max-height: calc(100vh - 360px) !important;
    min-height: calc(100vh - 360px) !important;
    /* background-color: red; */
    overflow-y: auto;
  }
}

.show {
  display: block;
  padding: 0px;
  padding-left: 20px;
  position: absolute !important;
  bottom: 30px !important;
  width: 100vw !important;
  left: 0px !important;
  height: 24px !important;
}
#root {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
textarea:focus,
input:focus {
  outline: none;
}
.options {
  /* color: white; */
  padding: 8px;
  /* background-color: rgb(0, 157, 255); */
  border: 1px solid rgb(0, 145, 235);
  margin-right: 10px;
  margin-bottom: 16px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  min-width: 80px;
  text-align: center;
}
.options:hover {
  background-color: rgb(235, 247, 255);
}
.selected:hover {
  background-color: rgb(0, 157, 255) !important;
}
.animationFadeIn1 {
  animation: 0.5s ease 0s normal forwards 1 fadein1;
}
.animationFadeIn2 {
  animation: 1s ease 0s normal forwards 1 fadein2;
}

@keyframes fadein1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein2 {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.MainQuestion {
  /* border: 1px solid #ddd; */
  border-radius: 20px;
  border-top-left-radius: 3px;
  padding: 0px 20px;
  width: 100%;
  display: inline-block;
}
h3,
h4 {
  line-height: 0px;
}
h4 {
  font-size: 30px !important;
}
.center {
  width: 100%;
  margin: auto;
  padding: 16px;
}
.yourAssistant {
  display: flex;
  margin-top: 20px;
}
.rightSide {
  padding: 8px 0px;
}
.image img {
  height: 48px;
}
.tenantName {
  font-size: 16px;
}
.assistant {
  font-size: 12px;
  padding: 4px 0px;
  color: #666;
}
.moveToNext {
  text-align: right;
  padding: 10px;
}
.NextButton {
  color: white;
  cursor: pointer;
  padding: 14px 28px;
  border-radius: 7px;
  font-size: 16px;
  
  background-color: rgb(0, 157, 255);
  margin-right: 8px;
}
.selectButton {
  color: white;
  cursor: pointer;
  padding: 14px 28px;
  border-radius: 7px;
  font-size: 16px;
  
  background-color: rgb(0, 157, 255);
  margin-right: 8px;
}
.notAllowed {
  cursor: wait;
  color: white;
  padding: 14px 28px;
  border-radius: 7px;
  font-size: 16px;
  margin-right: 8px;
  background-color: rgb(72, 183, 251);
}
.thankscenter {
  text-align: center;
  margin-top: 80px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thanks {
  font-size: 40px;
}
.thanksinfo {
  max-width: 400px;
  align-self: center;
  font-size: 16px;
  line-height: 30px;
  margin-top: 16px;
}
.thanksImg {
  height: 100px;
}
.BackButton {
  color: rgb(0, 157, 255);
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 7px;
  float: left;
  margin-right: 8px;
  margin-top: -16px;
  /* background-color: rgb(0, 157, 255); */
}
.selectedAns {
  padding: 8px 16px;
}
.selected {
  color: white;
  background-color: rgb(0, 157, 255);
}
.textBoxArea {
  padding: 10px 0px;
  width: 320px;
}
.textMBoxArea {
  padding: 10px 0px;
  width: 100%;
}
.passeng {
  display: flex;
  padding: 8px;
  padding-left: 0px;
}
.actButton {
  padding: 2px 26px;
  text-align: center;
  border: 1px solid rgb(0, 157, 255);
  cursor: pointer;
  color: rgb(0, 157, 255);
  font-size: 24px;
}
.NewLine {
  border-bottom: 1px dashed #ccc;
  margin-bottom: 14px;
}
.actCount {
  width: 200px;
  padding: 10px 4px;
  text-align: center;
  margin-right: 8px;
  margin-left: 8px;
  border: 1px solid rgb(0, 157, 255);
  color: rgb(0, 157, 255);
  font-size: 16px;
}
.textBoxArea input {
  /* width: calc(100% - 50px);
  padding: 10px;
  border: 1px solid rgb(0, 157, 255);
  border-radius: 4px;
  font-size: 16px; */
}
.header {
  margin-bottom: 30px;
  font-size: 20px;
  max-width: 600px;
}
.header h3 {
  margin-bottom: 30px;
}
.Modal {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 480px;
  top: 10%;
  left: 0px;
  text-align: center;
}

.ModalContent {
  width: 800px;
  background-color: white;
  border-radius: 18px;
  display: inline-block;
  min-height: 481px;
}
.mainHeaderBG {
  height: 200px;
  background-image: url(/static/media/01.31fc5787.jpg);
  opacity: 0.6;
}
.mainLogo {
  background-image: url(/static/media/logo.86609ba2.svg);
  background-repeat: no-repeat;
  height: 48px;
  margin-top: -80px;
  position: absolute;
  z-index: 1;
  width: 400px;
  margin-left: 52px;
}
.subLog {
  background-image: url(/static/media/logo.86609ba2.svg);
  background-repeat: no-repeat;
  height: 48px;
  z-index: 1;
  max-width: 400px;
}
.headTitle {
  padding-top: 40px;
  padding-bottom: 60px;
}
.Step {
  cursor: pointer;
}
.Box {
  display: flex;
}
.Box .left {
  width: 240px;
}
.Box .right {
  text-align: left;
  padding: 0px 20px;
  width: calc(100% - 200px);
}
.errorValidation {
  color: orangered;
  margin: 0px 10px;
  height: 44px;
}
.full {
  text-align: left;
  padding: 20px;
  width: 100%;
}
.Trans {
  background-color: black;
  opacity: 0.2;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

h3 {
  font-size: 22px !important;
  line-height: 28px !important;
  margin-bottom: 16px !important;
}
h4 {
  font-size: 18px !important;
  line-height: 24px !important;
  margin-bottom: 16px !important;
}
.SubTitle {
  margin-top: 16px;
  margin-bottom: 20px !important;
}
select {
  padding: 10px 20px;
  margin: 6px !important;
  font-size: 16px !important;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 3px;
  min-width: 100px;
  border: 1px solid rgb(0, 157, 255);
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='rgb(0, 157, 255)'/></g></svg>")
    no-repeat;
  cursor: pointer;
  background-position: right 5px top 50%;
}
.mainPage {
  margin: auto;
  padding: 20%;
  text-align: center;
}
.mainPage .mainPageButton {
  padding: 18px 28px;
  background-color: rgb(0, 145, 235);
  color: white;
  cursor: pointer;
  border-radius: 20px;
  font-size: 18px;
}
.ChildAge {
  display: block;
  padding-left: 4px;
  font-size: 14px;
}
.summery {
  font-size: 18px;
  line-height: 26px;
}
.summery span {
  color: rgb(0, 145, 235);
}
.hide {
  display: none;
}
/* .show {
  display: block;
  padding: 10px;
  padding-left: 20px;
  position: absolute;
  bottom: 8px;
  width: 100%;
} */
.rs-steps-vertical .rs-steps-item:not(:first-child) {
  margin-top: 4px !important;
}
.rs-steps-vertical .rs-steps-item-tail {
  top: 35px !important;
}
.rs-steps-vertical .rs-steps-item {
  padding-bottom: 24px !important;
}
.rs-steps-item-content {
  text-align: left !important;
}
.Explanation {
  margin-bottom: 20px;
  margin-top: -10px;
  color: rgb(173, 172, 172);
  font-size: 14px;
  font-style: italic;
}
.tripLocation {
  text-align: right;
  font-size: 16px;
  margin-bottom: 24px;
  height: 22px;
  color: rgb(0, 145, 235);
}
.flex {
  display: flex;
  width: 100%;
}
.marginRight {
  margin-right: 8px;
}
.maxArea {
  max-height: 400px;
  min-height: 300px;
  overflow-y: auto;
}
.ChildrenAgeSet {
  display: inline-block;
  /* background-color: red; */
  margin-top: 10px;
  margin-right: 4px;
}
.RoomArrangement {
  padding: 5px;
  margin-top: 10px;
}

.rooms {
  border: 1px solid rgb(0, 145, 235);
  border-radius: 3px;
  max-width: 400px;
  display: block;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 6px;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.roomHeader {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.roomsTitle {
  display: flex;
  width: 100%;
}
.roomsTitle svg {
  margin-top: -2px;
}
.roomsTitle div {
  margin-top: 2px;
  padding-left: 6px;
}
.actionBox {
  display: flex;
}
.nme {
  width: 100%;
  padding: 4px;
}
.roomAction {
  padding-top: 6px;
}
.roomCount {
  width: 32px;
  text-align: center;
}
.roomBtn {
  font-size: 20px;
  padding: 0px 4px;
  margin-top: -4px;
  color: rgb(0, 145, 235);
  cursor: pointer;
}
.roomsRemove {
  float: right;
  /* background-color: rgb(0, 145, 235); */
  color: white;
  margin-top: -4px;
  font-size: 14px;
  border-radius: 20px;
  padding: 2px 4px;
}
.PlusIconRight {
  margin-left: 30px;
  margin-top: 0px;
  /* position: absolute; */
  font-size: 14px;
  display: flex;
  color: rgb(0, 145, 235);
  cursor: pointer;
}
.flex {
  display: flex;
}
.roomsRemove {
  cursor: pointer;
  color: #666;
}
.disabled {
  cursor: not-allowed;
  color: gray;
}
.roomButton {
  font-size: 20px;
}
.roomsRemove:hover {
  color: black;
}
.PlusIconRight span {
  display: inline-block;
  margin-top: 0px;
}
.roomInfo {
  padding: 4px;
  /* padding-left: 40px; */
  margin-top: 8px;
  text-align: center;
  color: rgb(0, 145, 235);
  font-size: 14px;
}

.displayNone {
  display: none !important;
}
.loaderMain {
  /* background-color: red; */
  position: absolute;
  text-align: center;
  margin-left: 40px;
  padding: 80px;
}
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px; /* Safari */
  animation: spin 2s linear infinite;
}
.inlineloader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  margin-right: 10px; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.PopUpElement {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  justify-content: center;
  top: 200px;
  z-index: 1234567890;
}
.PopUpElementComp {
  display: inline-block;
  background-color: white;
  border: 1px solid #3498db;
  position: relative;
  z-index: 1234567890;
}
.popUpBG{
  background-color: black;
  opacity: 0.3;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.okButton{
  height: 40px;
  margin-bottom: 80px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

